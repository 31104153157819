import { clsx } from "clsx";
import styles from "./Flex.module.scss";

interface FlexProps extends React.HTMLProps<HTMLDivElement> {
  align?: "start" | "center" | "end" | "stretch";
  gap?: "xxs" | "xs" | "sm" | "md" | "lg" | "unset";
  mb?: "xs" | "sm" | "md" | "lg";
  flow?: "row" | "column" | "row-wrap";
  justify?: "start" | "center" | "end" | "space-between" | "space-evenly";
  fullWidth?: boolean;
  fullHeight?: boolean;
}

export const Flex: React.FC<FlexProps> = ({
  className,
  align = "center",
  gap = "md",
  justify = "start",
  mb,
  flow = "row",
  fullWidth,
  fullHeight,
  ...props
}) => (
  <div
    {...props}
    className={clsx(
      styles["flex"],
      styles[`align-${align}`],
      styles[`gap-${gap}`],
      styles[`justify-${justify}`],
      styles[`flow-${flow}`],
      {
        [styles[`mb-${mb}`]]: !!mb,
        [styles["full-width"]]: fullWidth,
        [styles["full-height"]]: fullHeight,
      },
      className,
    )}
  />
);

import React, { ReactElement } from "react";
import styles from "./Input.module.scss";
import { clsx } from "clsx";
import { Icon } from "../Icon";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: ReactElement;
  actionIcon?: ReactElement;
  errorIcon?: ReactElement;
  errorHelpText?: string;
  loading?: boolean;
  error?: string;
  isInvalid?: boolean;
}

/**
 * Abridge Input UI component for simple text input
 */
export const Input = ({
  type = "text",
  icon,
  actionIcon,
  className,
  loading,
  error,
  errorIcon,
  isInvalid,
  ...props
}: InputProps): ReactElement => {
  return (
    <div className={clsx(styles["abridge-input-root"], className)}>
      {icon && <span className={styles["prefix"]}>{icon}</span>}
      <input
        {...props}
        type={type}
        className={clsx({
          [styles["has-icon"]]: Boolean(icon),
          [styles["has-error"]]: Boolean(error),
          [styles["invalid"]]: Boolean(isInvalid),
        })}
      />
      <div className={styles["suffix"]}>
        {loading && (
          <Icon
            className={styles["progress"]}
            type="progress"
            color="accent"
            size="sm"
          />
        )}

        {actionIcon}

        {error && errorIcon}
      </div>
      {error && <div className={styles["error"]}>{error}</div>}
    </div>
  );
};

/**
 * Abridge Input UI component for labels
 */
const InputLabel: React.FC<React.HTMLProps<HTMLLabelElement>> = (props) => {
  return (
    <label
      {...props}
      className={clsx(styles["abridge-input-label"], props.className)}
    />
  );
};

Input.Label = InputLabel;
